import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'progressCircle', 'ratio'];

  connect() {
    this.updateProgress();
  }

  updateProgress() {
    const circle = this.progressCircleTarget;
    const checkboxTotal = this.checkboxTargets.length;
    const checkedCheckboxTotal = this.checkboxTargets.filter(checkbox => checkbox.checked).length;

    const radius = parseFloat(circle.getAttribute("r"));
    const circumference = 2 * Math.PI * radius;
    circle.style.strokeDasharray = circumference;

    const offset = circumference - (checkedCheckboxTotal / checkboxTotal) * circumference;
    circle.style.strokeDashoffset = offset;

    this.ratioTarget.textContent = `${checkedCheckboxTotal}/${checkboxTotal}`;
  }
}
